import { CarTableData } from 'api/driverama/stock/carSearch'
import { LINKS } from 'constants/links'
import { encodeJWT } from 'driverama-core/utils/jwt'

// utility that creates link from STOCK to ECOM web
export function getEcomWebLink(data: CarTableData) {
  const { states, make, model, stockNo } = data

  const isCarSellingOrDelivered =
    states?.sellingState === 'SOLD' || states?.sellingState === 'DELIVERED'

  const token = encodeJWT(
    { id: data.id },
    process.env.NEXT_PUBLIC_ECOM_URL_SECRET as string
  )

  const formattedMake = make.replaceAll(' ', '-').toLowerCase()
  const formattedModel = model.replaceAll(' ', '-').toLowerCase()

  const baseURL = LINKS.ecomWebWithMakeAndModel(
    stockNo,
    formattedMake,
    formattedModel
  )

  return isCarSellingOrDelivered ? `${baseURL}?code=${token}` : baseURL
}
