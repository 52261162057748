import { SearchStockCarResponse } from 'api/driverama/stock/carDetail'
import { safelyAssertUnreachable } from 'driverama-core/utils/types'
import { TFunction, useTranslation } from 'react-i18next'
import { logisticsStates } from 'sections/carDetail/carInfoHeader/CarInfoHeader.utils'
import { NotSellReason } from './useNotSellReasons'

export type LogisticState = NonNullable<
  NonNullable<SearchStockCarResponse['states']>['logisticState']
>

export type LogisticStateOption = {
  label: string
  value: LogisticState
}

export const getLogisticStateLabel = (
  label: LogisticState,
  t: TFunction<'core'[]>,
  notSellReason?: NotSellReason | null
) => {
  switch (label) {
    case 'WASHING':
      return t('core:location_status_washing')
    case 'READY_TO_SELL':
      return t('core:location_status_ready_to_sell')
    case 'NOT_SELL':
      return `${t('core:location_status_not_sell')} ${
        notSellReason
          ? `- ${t(`core:car_states_not_sell_state_${notSellReason}`)}`
          : ''
      }`
    case 'STORE':
      return t('core:location_status_store')
    case 'REPAIRING':
      return t('core:location_status_repairing')
    case 'TUV':
      return t('core:location_status_tuv')
    case 'REPAIRED':
      return t('core:location_status_repaired')
    case 'READY_TO_TRANSPORT':
      return t('core:location_status_ready_to_transport')
    case 'IN_TRANSPORT':
      return t('core:location_status_in_transport')
    case 'WAITING':
      return t('core:location_status_waiting')
    case 'IN_MOSOLF':
      return t('core:location_status_in_mosolf')
    case 'CHECK':
      return t('core:location_status_check')
    case 'READY_TO_PHOTO':
      return t('core:location_status_ready_to_photo')
    case 'STOCK':
      return t('core:location_status_stock')
    case 'TEST_DRIVE_CUSTOMER':
      return t('core:location_status_test_drive_customer')
    case 'OLD_STOCK_TEST':
      return t('core:location_status_old_stock_test')
    case 'PREPARATION':
      return t('core:location_status_preparation')
    case 'READY_TO_DELIVERY':
      return t('core:location_status_ready_to_delivery')
    case 'HANDED_TO_CUSTOMER':
      return t('core:location_status_handed_to_customer')
    case 'AURES_RESERVATION':
      return t('core:location_status_aures_reservation')
    case 'WAITING_FOR_REPAIR':
      return t('core:location_status_waiting_for_repair')
    case 'PAID':
      return t('core:location_status_paid')
    case 'ORDERED':
      return t('core:location_status_ordered')
    default:
      return safelyAssertUnreachable(label)
  }
}

export function useLogisticStatesLabelResolver() {
  const { t } = useTranslation(['core'])

  const resolver = (notSellReason?: NotSellReason | null) => {
    return logisticsStates.reduce<LogisticStateOption[]>((arr, item) => {
      const label = getLogisticStateLabel(item, t, notSellReason)
      if (label) {
        arr.push({ label, value: item })
      }
      return arr
    }, [])
  }

  return resolver
}
