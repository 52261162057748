import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { operations } from 'driverama-core/api/driverama/generated/files'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { apiAuthFetcher } from 'utils/fetcher'

export type FilesSearchResponse = operations['fileSearch']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryBody = operations['fileSearch']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<unknown, unknown, FilesSearchResponse>

function useSearchFileQuery(body: QueryBody = { ids: [] }, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.searchFiles(body),
    async () => {
      const res = await apiAuthFetcher<FilesSearchResponse>(URLS.searchFiles, {
        method: HTTPMethod.POST,
        body
      })

      return res.json
    },
    opts
  )
}

export function useSearchQrFile(qrId: Maybe<string>) {
  const { data, ...rest } = useSearchFileQuery(
    { ids: qrId ? [qrId] : [] },
    { enabled: !!qrId, refetchOnWindowFocus: false }
  )

  const qrFile = useMemo(() => {
    return data?.content.find(qr => qr.id === qrId)
  }, [qrId, data])

  return { ...rest, data, qrFile }
}
