import { SearchStockCarResponse } from 'api/driverama/stock/carDetail'
import { safelyAssertUnreachable } from 'driverama-core/utils/types'
import { TFunction, useTranslation } from 'react-i18next'

export type SellingState = NonNullable<
  SearchStockCarResponse['states']
>['sellingState']

export interface SellingStateOption {
  label: string
  value: SellingState
  order?: number
}

export const getSellingStateLabel = (
  status: SellingState,
  t: TFunction<'core'[]>
) => {
  switch (status) {
    case 'BRANCH':
      return t('core:selling_status_branch')
    case 'DELIVERED':
      return t('core:selling_status_delivered')
    case 'SELLING':
      return t('core:selling_status_selling')
    case 'SELL_PREPARATION':
      return t('core:selling_status_sell_preparation')
    case 'SOLD':
      return t('core:selling_status_sold')
    case 'EXPORT':
      return t('core:selling_status_export')
    case 'RETURNED':
      return t('core:selling_status_returned')
    default:
      return safelyAssertUnreachable(status)
  }
}

export const getSellingStateLabelOrdered = (
  status: SellingState,
  t: TFunction<'core'[]>
) => {
  switch (status) {
    case 'BRANCH':
      return { label: t('core:selling_status_branch'), order: 1 }
    case 'DELIVERED':
      return { label: t('core:selling_status_delivered'), order: 5 }
    case 'SELLING':
      return { label: t('core:selling_status_selling'), order: 3 }
    case 'SELL_PREPARATION':
      return { label: t('core:selling_status_sell_preparation'), order: 2 }
    case 'SOLD':
      return { label: t('core:selling_status_sold'), order: 4 }
    case 'EXPORT':
      return { label: t('core:selling_status_export'), order: 6 }
    case 'RETURNED': {
      return { label: t('core:selling_status_returned'), order: 7 }
    }
    default:
      return safelyAssertUnreachable(status)
  }
}

export function useSellingState(states: readonly SellingState[]) {
  const { t } = useTranslation(['core'])

  return states.reduce<SellingStateOption[]>((arr, item) => {
    const label = getSellingStateLabel(item, t)
    if (label) {
      arr.push({
        label,
        value: item
      })
    }
    return arr
  }, [])
}
