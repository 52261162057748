import { paths } from 'driverama-core/api/driverama/generated/lov'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { lovQueryOptions } from './lov.utils'

export type LovBodiesSearchResponse =
  | paths['/lov/bodies/search']['post']['responses']['200']['content']['application/com.driverama-v1+json']
  | undefined
export type BodiesSearchRequestBody = paths['/lov/bodies/search']['post']['requestBody']['content']['application/json']
type QueryOptions = UseQueryOptions<unknown, unknown, LovBodiesSearchResponse>

async function fetchLovBodiesSearch(body: BodiesSearchRequestBody) {
  const res = await apiFetcher<LovBodiesSearchResponse>(URLS.lovBodiesSearch, {
    method: HTTPMethod.POST,
    body
  })

  return res.json
}

export function useLovBodiesSearchQuery(
  body: BodiesSearchRequestBody,
  options?: QueryOptions
) {
  return useQuery(
    QUERY_KEYS.lovBodiesSearch(body),
    async () => fetchLovBodiesSearch(body),
    {
      ...lovQueryOptions,
      ...options
    }
  )
}

export function useBodiesSearchList(modelIds?: string[], makeIds?: string[]) {
  const { data, ...rest } = useLovBodiesSearchQuery({
    ids: [],
    active: true,
    modelIds,
    makeIds
  })

  const bodies = useMemo(
    () =>
      data?.content?.map(body => ({
        label: body.name ?? body.id,
        value: body.id
      })) ?? [],
    [data]
  )

  return { bodies, ...rest }
}
