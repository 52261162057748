import { operations } from 'driverama-core/api/driverama/generated/lov'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { isNotNil, Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { lovQueryOptions } from './lov.utils'

export type LovFuelTypesResponse = operations['getFuelTypes']['responses']['200']['content']['application/com.driverama-v1+json']
export type LovFuelType = LovFuelTypesResponse['content'][number]
type QueryParams = operations['getFuelTypes']['parameters']['query']
type QueryOpts = UseQueryOptions<unknown, unknown, LovFuelTypesResponse>

export async function fetchLovFuelTypes(
  params?: QueryParams,
  headers?: HeadersInit
) {
  const searchParams = {
    ...params,
    sort: params?.sort?.[0]
  }

  const res = await apiFetcher<LovFuelTypesResponse>(URLS.lovFuelTypes, {
    searchParams,
    headers
  })

  return res.json
}

export function useLovFuelTypesQuery(params?: QueryParams, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.lovFuelTypes(params),
    async () => fetchLovFuelTypes(params),
    { ...lovQueryOptions, ...opts }
  )
}

export function useFuelTypeList(fuelTypeId?: string[]) {
  const { data, ...rest } = useLovFuelTypesQuery({
    active: true,
    ids: fuelTypeId
  })

  const fuelTypes = useMemo(() => {
    return (
      data?.content.map(fuelType => ({
        label: fuelType.name || fuelType.id,
        value: fuelType.id
      })) || []
    )
  }, [data])

  return { fuelTypes, ...rest }
}

export type FuelType = ReturnType<typeof useFuelTypeList>['fuelTypes'][number]

export function getFuelTypeDetailQueryParams(
  fuelTypeIds: Maybe<string>[]
): QueryParams {
  return {
    active: true,
    ids: fuelTypeIds.filter(isNotNil)
  }
}

export function useFuelTypeDetailQuery(
  fuelTypeId: Maybe<string>,
  alternativeFuelTypeId: Maybe<string>,
  opts?: QueryOpts
) {
  const queryResult = useLovFuelTypesQuery(
    getFuelTypeDetailQueryParams([fuelTypeId, alternativeFuelTypeId]),
    {
      enabled: !!fuelTypeId || !!alternativeFuelTypeId,
      ...opts
    }
  )

  const fuelType = useMemo(() => {
    return queryResult.data?.content.find(item => item.id === fuelTypeId)
  }, [fuelTypeId, queryResult.data])

  const alternativeFuelType = useMemo(() => {
    return queryResult.data?.content.find(
      item => item.id === alternativeFuelTypeId
    )
  }, [alternativeFuelTypeId, queryResult.data])

  return { ...queryResult, fuelType, alternativeFuelType }
}
