import { InfiniteData } from 'react-query'

type PaginatedResponse<T> = {
  content: T[]
  totalElements: number
  totalPages: number
}

export function getDataFromPages<T>(data?: InfiniteData<PaginatedResponse<T>>) {
  return (data?.pages ?? []).reduce((newData, currPage) => {
    if (currPage && currPage.content) {
      newData.push(...currPage.content)
    }

    return newData
  }, [] as T[])
}
