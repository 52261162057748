import { css } from '@emotion/react'
import { useSearchQrFile } from 'api/driverama/stock/filesSearch'
import { Button } from 'driverama-core/components/button/Button'
import { Flex } from 'driverama-core/components/Flex'
import { Spacer } from 'driverama-core/components/spacer/Spacer'
import { SpinnerCentered } from 'driverama-core/components/spinner/SpinnerCentered'
import { TextBody, TextHeader } from 'driverama-core/components/text/Text'
import { size } from 'driverama-core/styles/spacing'
import { downloadUrl } from 'driverama-core/utils/file'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'

interface Props<T extends Record<string, unknown>> {
  row: Row<T>
  qrCodePropertyName?: string
}

export function QrCodeModal<T extends Record<string, unknown>>({
  row,
  qrCodePropertyName = 'qrCodeFileId'
}: Props<T>) {
  const { t } = useTranslation(['core'])

  const { isLoading, qrFile } = useSearchQrFile(row.values[qrCodePropertyName])

  return (
    <Flex variant="column" align="center">
      <TextHeader variant="h3">{t('core:qr_code_modal_title')}</TextHeader>

      <Spacer size={5} />

      <TextBody size="large">{t('core:qr_code_modal_description')}</TextBody>

      {isLoading ? (
        <SpinnerCentered css={{ height: size(75) }} />
      ) : (
        <Image
          src={qrFile?.url ?? ''}
          width={size(75)}
          height={size(75)}
          objectFit="cover"
        />
      )}

      <TextHeader variant="h5">{row.values['stockNo']}</TextHeader>

      <Spacer size={12} />

      <Button
        onClick={() => {
          qrFile?.url && downloadUrl(qrFile.url)
        }}
        variant="outline"
        css={css`
          width: 100%;
        `}
      >
        {t('core:qr_code_modal_download')}
      </Button>
    </Flex>
  )
}
