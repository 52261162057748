import { SearchStockCarResponse } from 'api/driverama/stock/carDetail'
import { OnChangeHandlerType } from 'components/select/Select.utils'

import { LogisticState, LogisticStateOption } from 'utils/useLogisticStates'
import { NotSellReason } from 'utils/useNotSellReasons'
import { SellingStateOption, useSellingState } from 'utils/useSellingStates'

export const sellingStates = [
  'BRANCH',
  'SELLING',
  'SELL_PREPARATION',
  'SOLD',
  'DELIVERED',
  'EXPORT',
  'RETURNED'
] as const

export function useSellingStateSelectOptions() {
  const options = useSellingState(sellingStates)

  return options
}

export const logisticsStates = [
  'WASHING',
  'READY_TO_SELL',
  'NOT_SELL',
  'STORE',
  'REPAIRING',
  'TUV',
  'REPAIRED',
  'READY_TO_TRANSPORT',
  'IN_TRANSPORT',
  'WAITING',
  'IN_MOSOLF',
  'CHECK',
  'READY_TO_PHOTO',
  'STOCK',
  'TEST_DRIVE_CUSTOMER',
  'OLD_STOCK_TEST',
  'ORDERED',
  'PAID',
  'PREPARATION',
  'READY_TO_DELIVERY',
  'HANDED_TO_CUSTOMER',
  'AURES_RESERVATION',
  'WAITING_FOR_REPAIR'
] as const

export type NotSellStateValues = {
  reason: NotSellReason
  expectedSolution?: string
  description?: string
}

export type HeaderProps = {
  car: SearchStockCarResponse
  country?: string
  name?: string
  logisticStateOptions: LogisticStateOption[]
  sellingStateOptions: SellingStateOption[]
  openEditModeModal: () => Promise<void>
  onBeforeLogisticStateChange?: OnChangeHandlerType<LogisticState>
  disabled: boolean
  isUpdating: boolean
}
