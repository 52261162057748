import { getDataFromPages } from 'components/table/InfiniteTable.utils'
import { URLS } from 'constants/api'
import { QUERY_KEYS } from 'constants/queryKeys'
import { paths } from 'driverama-core/api/driverama/generated/stock'
import { useBodiesSearchList } from 'driverama-core/api/driverama/lov/lovBodiesSearch'
import { useActiveBranchList } from 'driverama-core/api/driverama/lov/lovBranchesSearch'
import { useFuelTypeList } from 'driverama-core/api/driverama/lov/lovFuelTypes'
import { useMakeList } from 'driverama-core/api/driverama/lov/lovMakes'
import { useModelList } from 'driverama-core/api/driverama/lov/lovModelsSearch'
import { useTransmissionList } from 'driverama-core/api/driverama/lov/lovTransmissions'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { createAcceptHeaderWithVersion } from 'driverama-core/utils/fetcher'
import { omitNilValues } from 'driverama-core/utils/object'
import { useMemo } from 'react'
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query'
import {
  ManagerFilters,
  MarketingFilters
} from 'sections/filters/Filters.utils'
import { apiAuthFetcher } from 'utils/fetcher'

export type TableDataQueryResponse = paths['/stock/stock-management/cars/search']['post']['responses']['200']['content']['application/com.driverama-v2+json']
export type CarTableData = NonNullable<
  TableDataQueryResponse['content']
>[number] & {
  make: string
  model: string
  modelMakeName: string
  fuelType: string | undefined | null
  body: string | undefined | null
  transmission: string | undefined | null
  location: string | undefined | null
}

export type CarTableDataSortKey =
  | 'id'
  | 'stockNo'
  | 'makeId'
  | 'modelId'
  | 'bodyId'
  | 'engineId'
  | 'transmissionId'
  | 'fuelTypeId'
  | 'yearOfMake'
  | 'actualMileageKm'
  | 'logisticState'
  | 'sellingState'
  | 'reason'
  | 'source'
  | 'sourceCountry'
  | 'price'
  | 'marketingLabel'
  | 'marketingHighlights'
  | 'carPreparationDeadline'
  | 'branchId'
  | 'states.updatedAt'

export type SearchCarParams = paths['/stock/stock-management/cars/search']['post']['parameters']['query']
export type SearchCarBody = paths['/stock/stock-management/cars/search']['post']['requestBody']['content']['application/json']

type QueryOpts = UseInfiniteQueryOptions<
  TableDataQueryResponse,
  [string, { pageParam?: number }],
  TableDataQueryResponse
>

export function useSearchCarQuery(
  body: SearchCarBody,
  searchParams?: SearchCarParams,
  opts?: QueryOpts
) {
  return useInfiniteQuery(
    QUERY_KEYS.searchCars({ ...body, ...searchParams }),
    async ({ pageParam = 0 }) => {
      const res = await apiAuthFetcher<TableDataQueryResponse>(
        URLS.searchCars,
        {
          searchParams: { page: pageParam, ...searchParams },
          headers: {
            Accept: createAcceptHeaderWithVersion(2)
          },
          method: HTTPMethod.POST,
          body
        }
      )

      return res.json as TableDataQueryResponse
    },
    {
      ...opts,
      getNextPageParam: (lastPage, pages) => {
        return pages.length <= (lastPage?.totalPages ?? 0)
          ? pages.length
          : undefined
      }
    }
  )
}

export function useCarLovs(
  filters: ManagerFilters | undefined,
  cars: TableDataQueryResponse['content'],
  opts?: QueryOpts
) {
  const { fuelTypes } = useFuelTypeList()
  const { transmissions } = useTransmissionList()
  const { carMakes } = useMakeList()
  const { models } = useModelList(undefined, true)
  const { bodies } = useBodiesSearchList(
    filters?.modelIds ?? undefined,
    filters?.makeIds ?? undefined
  )
  const { branches } = useActiveBranchList()

  const data = useMemo<CarTableData[] | undefined>(() => {
    if (
      !!cars &&
      !!carMakes &&
      !!models &&
      !!fuelTypes &&
      !!bodies &&
      !!transmissions
    ) {
      return cars.map(car => {
        const make = carMakes.find(item => item.id === car.makeId)
        const model = models.find(item => item.id === car.modelId)
        const fuelType = fuelTypes.find(item => item.value === car.fuelId)
        const body = bodies.find(item => item.value === car.bodyId)
        const transmission = transmissions.find(
          item => item.value === car.transmissionId
        )

        const carBranch = car.branchId

        const location = branches.find(branch => branch.id === carBranch)?.label

        return {
          ...car,
          make: make?.name ?? car.makeId,
          model: model?.name ?? car.modelId,
          modelMakeName: `${make?.name || car.makeId} ${
            model?.name || car.modelId
          }`,
          fuelType: fuelType?.label || car.fuelId,
          body: body?.label || car.bodyId,
          transmission: transmission?.label || car.transmissionId,
          location
        }
      })
    }
    return undefined
  }, [cars, carMakes, models, fuelTypes, bodies, transmissions, branches])

  return data
}

export function useSearchCar(
  filters?: ManagerFilters | MarketingFilters,
  searchParams?: SearchCarParams
) {
  let body: SearchCarBody = {}

  if (filters) {
    body = omitNilValues(filters)
  }

  const {
    data,
    isError,
    isLoading,
    isSuccess,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useSearchCarQuery(body, searchParams, { refetchOnWindowFocus: false })

  const pages = useMemo(() => getDataFromPages(data), [data])

  const populatedCars = useCarLovs(filters, pages)

  const cars = useMemo(() => {
    const make = populatedCars ?? []
    return make
  }, [populatedCars])

  return {
    cars,
    isLoading,
    isError,
    isSuccess,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    totalResults: data?.pages[0].totalElements
  }
}
