import { operations } from 'driverama-core/api/driverama/generated/lov'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { lovQueryOptions } from './lov.utils'

export type LovTransmissionsResponse = operations['getTransmissions']['responses']['200']['content']['application/com.driverama-v1+json']
export type LovTransmission = LovTransmissionsResponse['content'][0]
type QueryParams = operations['getTransmissions']['parameters']['query']
type QueryOpts = UseQueryOptions<unknown, unknown, LovTransmissionsResponse>

export async function fetchLovTransmissions(
  searchParams?: QueryParams,
  headers?: HeadersInit
) {
  const res = await apiFetcher<LovTransmissionsResponse>(
    URLS.lovTransmissions,
    { searchParams, headers }
  )

  return res.json
}

export function useLovTransmissionsQuery(
  searchParams?: QueryParams,
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovTransmissions(searchParams),
    async () => fetchLovTransmissions(searchParams),
    { ...lovQueryOptions, ...opts }
  )
}

export function useTransmissionList() {
  const { data, ...rest } = useLovTransmissionsQuery({ active: true })

  const transmissions = useMemo(() => {
    return (
      data?.content.map(transmission => ({
        label: transmission.name || transmission.id,
        value: transmission.id
      })) || []
    )
  }, [data])

  return { transmissions, ...rest }
}

export type Transmission = ReturnType<
  typeof useTransmissionList
>['transmissions'][number]

export function getTransmissionDetailQueryParams(): QueryParams {
  return { active: true }
}

export function useTransmissionDetailQuery(
  transmissionId: Maybe<string>,
  opts?: QueryOpts
) {
  const queryResult = useLovTransmissionsQuery(
    getTransmissionDetailQueryParams(),
    { enabled: !!transmissionId, ...opts }
  )

  const transmission = useMemo(() => {
    return queryResult.data?.content.find(item => item.id === transmissionId)
  }, [transmissionId, queryResult.data])

  return { ...queryResult, transmission }
}
